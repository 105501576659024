import React from 'react';
import { useLocation } from 'react-router';
// A custom hook that builds on useLocation to parse
// the query string for you.

function useWindowQuery() {
  return React.useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
}

// function useLocationQuery() {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

export default useWindowQuery;